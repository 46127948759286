<template>
  <div class="p-4 bg-white rounded-lg custom-container-height">
    <div class="mb-10 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-12 col-sm-12 col-md-9"
        :table_properties="reportStructure.properties"
      ></datatable-detail>
      <div v-if="hasExport" class="mr-4">
        <button @click="downloadItem" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Export
        </button>
      </div>
    </div>

    <div class="mx-2 mb-6">
      <Filters
        ref="filter"
        :submitFilterValues="submitFilterValues"
        :resetFilterValues="resetFilterValues"
        :filters="filter"
        :setTableFiltersValues="setTableFiltersValues"
      ></Filters>
    </div>
    <div v-if="loading" class="px-3">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </div>
    <template v-else-if="!loading && reportData">
      <div class="ma-4 pa-4 pt-0 mt-0">
        <!-- <span
          class="pl-8 poppins text-dark-65 font-size-h1 display5-md font-weight-medium"
        >
          {{ reportData.title }}
        </span> -->
        <v-data-table
          fixed-header
          light
          dense
          :items="items"
          :headers="headers"
          :items-per-page.sync="table_state.per_page"
          :page.sync="table_state.page"
          :server-items-length="reportData.values.total"
          class="m-2 mt-4 px-6 elevation-1"
          hide-default-footer
          mobile-breakpoint="300"
          height="600"
        >
          <template v-slot:no-data> NO DATA HERE!</template>
          <template v-slot:no-results> NO RESULTS HERE!</template>
          <template
            v-for="(head, i) in headers"
            v-slot:[`item.`+head.value]="{ value }"
          >
            <span class="second-text" :key="i">{{
              value === "-" ? "" : value
            }}</span>
          </template>
          <template
            v-for="(head, i) in headers"
            v-slot:[`header.`+head.value]="{ value }"
          >
            <span class="second-text font-weight-bold" :key="i">
              {{ head.text }}
            </span>
          </template>
          <!--          <template v-slot:body="body">-->
          <!--            <tr v-for="(item, index) in body.items" :key="index">-->
          <!--              <td v-for="(data, index2) in body.headers" :key="index2">-->
          <!--                {{ item[data.value] }}-->
          <!--              </td>-->
          <!--            </tr>-->
          <!--          </template>-->
        </v-data-table>
        <div class="pt-4 d-flex justify-space-between align-center flex-wrap">
          <v-col class="pr-0" cols="6" sm="3" lg="2" order="0">
            <PageInfo
              :page="table_state.page"
              :totalNumber="reportData.values.total"
              :perPage="table_state.per_page"
            ></PageInfo>
          </v-col>
          <v-col cols="12" sm="7" lg="8" order-sm="1" order="2">
            <v-pagination
              v-model="table_state.page"
              :length="
                Math.ceil(reportData.values.total / table_state.per_page)
              "
              total-visible="7"
              color="#a929e6"
              class="poppins"
              flat
              @input="updateTableState()"
            ></v-pagination>
          </v-col>

          <v-col cols="6" sm="2" order-sm="2" order="1">
            <v-select
              v-model="table_state.per_page"
              class="poppins"
              :items="pages"
              color="#a929e6"
              dense
              flat
              outlined
              hide-details
              @change="updateTableState(true)"
            ></v-select>
          </v-col>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Filters from "@/own/components/filter/Filters.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import PageInfo from "@/own/components/pagination/PageInfo.vue";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  name: "ReportSimpleDataTable",
  props: {
    /**
     * @description urls to fetch chart data
     * @type {{data_url: string, show_url: string}}
     */
    urls: {
      required: true,
      type: Object,
      default: () => ({ data_url: String, show_url: String }),
    },
    /**
     * @description for setting filters mandatory status
     * @values true, false, ['some_filter_name']
     * @type {boolean, [{title: string, name: string, valueFieldName: string}]}
     */
    mandatoryFilter: {
      type: [Boolean, Array],
      default: false,
    },
    showTableIfClient: {
      default: false,
    },
  },
  components: {
    Filters,
    DatatableDetail,
    PageInfo,
  },
  beforeMount() {
    this.getReportStructure().then(() => {
      if (this.showTableIfClient) {
        this.getReportData([
          {
            name: "client",
            value: [this.customerId],
          },
        ]);
      }
    });
  },
  data: () => ({
    hasExport: false,
    reportFilters: [
      {
        name: null,
        title: null,
        icon: null,
        type: null,
        related_columns: [],
        values: [],
      },
    ],
    filtersData: [],
    table_state: {
      page: 1,
      per_page: 50,
    },
    reportStructure: {
      properties: {
        title: "",
        description: "",
      },
      filters: [
        {
          name: "",
          title: "",
          type: "",
          related_columns: [],
          values: [
            {
              index: "",
              text: "",
            },
          ],
        },
      ],
      export: true,
    },
    reportData: null,
    loading: false,
  }),
  computed: {
    pages: function () {
      return [25, 50, 75, 100];
    },
    headers: function () {
      return this.reportData.values.header;
    },
    items: function () {
      return this.reportData.values.body;
    },
    filter: function () {
      return this.reportStructure.filters;
    },
    customerId: function () {
      return this.$store.getters.currentUser.data.customer_id;
    },
  },
  methods: {
    updateTableState(resetPage = false) {
      if (resetPage) {
        this.table_state.page = 1;
      }
      this.getReportData();
    },
    getReportStructure() {
      this.$store.commit(SET_PAGE_LOADING, true);
      return ApiService.post(this.urls.data_url)
        .then(({ data }) => {
          this.reportStructure = data.data;
          this.hasExport = data.data?.export;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },

    getReportData(customFilter = []) {
      // this.$store.commit(SET_PAGE_LOADING, true);
      this.loading = true;

      if (this.customerId) {
        this.filtersData = [
          ...this.filtersData,
          {
            name: "client",
            value: [this.customerId],
          },
        ];
      }

      const payload = {
        filters: customFilter.length ? customFilter : this.filtersData,
        ...this.table_state,
      };

      ApiService.post(this.urls.show_url, payload)
        .then(({ data }) => {
          if (!this.reportData) {
            this.reportDatatableHeaders = data.charts[0].values.header;
          }
          this.reportData = data.charts[0];
          if (this.reportData.values?.is_via_email) {
            Swal.fire({
              title: "Task accepted",
              text: "Export data will send to your email after getting ready!",
              type: "success",
            });
          }
        })
        .catch()
        .finally(() => {
          // this.$store.commit(SET_PAGE_LOADING, false);
          this.loading = false;
        });
    },
    updateTableContent() {
      this.$forceUpdate();
    },
    /**
     * validate requirement of filters
     * @returns Boolean
     */
    validateFilterValues() {
      let is_valid = true;
      // if mandatoryFilter array then checks are all filters located in array are selected
      if (Array.isArray(this.mandatoryFilter)) {
        this.mandatoryFilter.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name + filter.valueFieldName) {
              if (
                data.value !== "" &&
                data.value !== null &&
                data.value !== undefined
              ) {
                is_exist = true;
              }
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      // if mandatoryFilter is true then check all filter values are selected
      else if (this.mandatoryFilter) {
        this.filters.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name) {
              is_exist = true;
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      return is_valid;
    },
    submitFilterValues() {
      if (this.filtersData && this.validateFilterValues()) {
        this.getReportData();
      } else {
        if (Array.isArray(this.mandatoryFilter)) {
          let message = this.mandatoryFilter
            .map((filter) => filter.title)
            .join(",");
          Swal.fire(
            "Warning!",
            `Select ${message} filter${
              this.mandatoryFilter.length > 1 ? "s" : ""
            }!`,
            "warning"
          );
        } else if (this.mandatoryFilter) {
          Swal.fire("Warning!", "Select all filters!", "warning");
        }
      }
    },
    setTableFiltersValues(val) {
      this.filtersData = val;
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$refs.filter.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.filtersData = [];
      this.reportData = null;
      this.$store.commit(SET_PAGE_LOADING, false);
    },
    downloadItem() {
      if (this.validateFilterValues()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          filters: this.filtersData,
        };
        ApiService.post(`${this.urls.data_url}/export`, payload)
          .then(({ data }) => {
            const link = document.createElement("a");
            link.href = data.url;
            link.download = "Export.csv";
            link.click();
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.export.toggleModal();
            link.remove();
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
    },
  },
  // watch: {
  //   table_state: {
  //     deep: true,
  //     handler: function () {
  //       this.getReportData();
  //     },
  //   },
  // },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 126px) !important;
  }
}
</style>
